import React from 'react';
import { NavLink } from 'react-router-dom';
import httpClient from '../../httpClient';
import './NavigationBar.css'

interface NavigationBarProps {
  isAuthenticated: boolean;
  isSecureAuthSet: boolean;
}

const NavigationBar: React.FC<NavigationBarProps> = ({ isAuthenticated, isSecureAuthSet }) => {

  const logoutUser = async () => {
    await httpClient.post('/logout');
    window.location.href = '/';
  }

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-black">
      <div className="container">
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <a className="nav-link" href="javascript:history.go(-1)">Back</a>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/">Home</NavLink>
            </li>
            {isAuthenticated && isSecureAuthSet && (
              <React.Fragment>
              <li className="nav-item">
                <NavLink className="nav-link" to="/profile">Profile</NavLink>
              </li>
              </React.Fragment>
            )}
            {!isAuthenticated && isSecureAuthSet && (
              <React.Fragment>
                {/* <li className="nav-item">
                  <NavLink className="nav-link" to="/signup">Sign up</NavLink>
                </li> */}
                <li className="nav-item">
                  <NavLink className="nav-link" to="/login">Login</NavLink>
                </li>
              </React.Fragment>
            )}
            {isAuthenticated && isSecureAuthSet && (
              <li className="nav-item">
                <a className="nav-link">
                  <button className="nav-link logout-button" onClick={logoutUser}>
                    Logout
                  </button>
                </a>
              </li>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavigationBar;
