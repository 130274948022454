import React, { useState } from 'react';
import httpClient from '../../httpClient'; // Make sure to import your HTTP client or fetch function

const RegisterPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleRegister = async (e: any) => {
    e.preventDefault();

    try {
      const response = await httpClient.post('/register', { email, password });
      
      // Handle successful register response here (e.g., redirect to dashboard)
      console.log(response.data)
      if (response.status === 200){
        window.location.href = '/';
    }
    } catch (error: any) {
      // Handle register error here (e.g., show error message)
      console.error('Register error:', error);
      if (error.response.status === 401) {
        alert('Invalid credentials!')
      }
    }
  };

  return (
    <div className="register-container">
      <form className="register-form" onSubmit={handleRegister}>
        <h2>Register</h2>
        <div className="form-group">
          <label>Email</label>
          <input
            type="email"
            className="form-control"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Password</label>
          <input
            type="password"
            className="form-control"
            placeholder="Enter your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="btn btn-primary">
          Register
        </button>
      </form>
    </div>
  );
};

export default RegisterPage;