import React, { useState } from 'react';
import httpClient from '../../httpClient';
import './NewProject.css'

const NewProject = () => {
    
    const [ipAddresses, setIpAddresses] = useState([
        {
            ipAddress: '',
            selectedExporters: [],
        },
    ]);
    const [projectName, setProjectName] = useState('');
    const [isPushSelected, setIsPushSelected] = useState(false);
    const [emails, setEmails] = useState<string[]>(['']);
    const [authNeeded, setAuthNeeded] = useState(false);
    const [authOptions, setAuthOptions] = useState<{ [key: string]: 'basic' | 'no-auth' }>({});
    const [usernameOptions, setUsernameOptions] = useState<{ [key: string]: string }>({});
    const [passwordOptions, setPasswordOptions] = useState<{ [key: string]: string }>({});
    const [notificationsNeeded, setNotificationsNeeded] = useState(false);
    const [notificationOptions, setNotificationOptions] = useState<{ [key: string]: boolean }>({});
    const [discordEnabled, setDiscordEnabled] = useState(false);
    const [emailEnabled, setEmailEnabled] = useState(false);
    const [telegramEnabled, setTelegramEnabled] = useState(false);
    const [telegramChatId, setTelegramChatId] = useState('');
    const [telegramBotToken, setTelegramBotToken] = useState('');
    const [notification_email, setNotificationEmail] = useState('');
    const [notification_discord, setNotificationDiscord] = useState('');
    
    const handleNotificationsToggle = () => {
        setNotificationsNeeded(!notificationsNeeded);
    };

    const handleNotificationOptionChange = (option: string) => {
        setNotificationOptions(prevOptions => ({
            ...prevOptions,
            [option]: !prevOptions[option],
        }));
    };

    const handleAuthToggle = () => {
        setAuthNeeded(!authNeeded);
    };

    const handleAuthOptionChange = (key: string, option: 'basic' | 'no-auth') => {
        setAuthOptions(prevOptions => ({
            ...prevOptions,
            [key]: option,
        }));

        if (option === 'basic') {
            setUsernameOptions(prevOptions => ({
                ...prevOptions,
                [key]: '',
            }));
            setPasswordOptions(prevOptions => ({
                ...prevOptions,
                [key]: '',
            }));
        } else {
            setUsernameOptions(prevOptions => {
                const updatedOptions = { ...prevOptions };
                delete updatedOptions[key];
                return updatedOptions;
            });
            setPasswordOptions(prevOptions => {
                const updatedOptions = { ...prevOptions };
                delete updatedOptions[key];
                return updatedOptions;
            });
        }
    };

    const handleIpAddressChange = (index: number, value: string) => {
        const updatedIpAddresses = [...ipAddresses];
        updatedIpAddresses[index].ipAddress = value;
        setIpAddresses(updatedIpAddresses);
    };

    const handleExporterCheckboxChange = (index: number, exporter: never) => {
        const updatedIpAddresses = [...ipAddresses];
        const selectedExporters = updatedIpAddresses[index].selectedExporters;
        const exporterIndex = selectedExporters.indexOf(exporter);

        if (exporterIndex === -1) {
            selectedExporters.push(exporter);
        } else {
            selectedExporters.splice(exporterIndex, 1);
        }

        setIpAddresses(updatedIpAddresses);
    };

    const handleEmailChange = (index: number, value: string) => {
        const updatedEmails = [...emails];
        updatedEmails[index] = value;
        setEmails(updatedEmails);
    };

    const addIpAddress = () => {
        setIpAddresses([...ipAddresses, { ipAddress: '', selectedExporters: [] }]);
    };

    const deleteIpAddress = (index: number) => {
        const updatedIpAddresses = [...ipAddresses];
        updatedIpAddresses.splice(index, 1);
        setIpAddresses(updatedIpAddresses);
    };

    const addEmail = () => {
        setEmails([...emails, '']);
    };

    const deleteEmail = (index: number) => {
        const updatedEmails = [...emails];
        updatedEmails.splice(index, 1);
        setEmails(updatedEmails);
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        const completeFormData = {
            projectName,
            isPushSelected,
            userEmails: emails,
            ipAddressesData: ipAddresses.map((ipAddress, index) => ({
                ...ipAddress,
                exporterAuth: ipAddress.selectedExporters.map(exporter => ({
                    exporterName: exporter,
                    authOption: authOptions[`${index}-${exporter}`] || 'no-auth',
                    username: usernameOptions[`${index}-${exporter}`] || '',
                    password: passwordOptions[`${index}-${exporter}`] || '',
                })),
            })),
            notificationsNeeded,
            notificationOptions,
            telegramChatId,
            telegramBotToken,
            notification_email,
            notification_discord,
        };
        try {
            console.log(completeFormData);
            const response = await httpClient.post('/addproject', completeFormData);
            console.log(response.data);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const resetForm = () => {
        setIpAddresses([
          {
            ipAddress: '',
            selectedExporters: [],
          },
        ]);
        setProjectName('');
        setIsPushSelected(false);
        setEmails(['']);
        setAuthNeeded(false);
        setAuthOptions({});
        setUsernameOptions({});
        setPasswordOptions({});
        setNotificationsNeeded(false);
        setNotificationOptions({});
        setDiscordEnabled(false);
        setEmailEnabled(false);
        setTelegramEnabled(false);
        setTelegramChatId('');
        setTelegramBotToken('');
        setNotificationDiscord('');
        setNotificationEmail('');
      };

    return (
        <div className="row">
            <div className="col-md-6 offset-md-3">
                <form onSubmit={handleSubmit} className='newproject-form'>
                    <div className="form-group">
                        <label htmlFor="project-name">Project Name:</label>
                        <input
                            type="text"
                            className="form-control"
                            id="project-name"
                            value={projectName}
                            onChange={(e) => setProjectName(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Scheme:</label>
                        <div className="scheme-row">
                            <label className='scheme-name-label'>
                                Pull
                            </label>
                            <label className='toggle-switch scheme-choosing-checkbox'>
                                <input
                                    type="checkbox"
                                    name={`scheme`}
                                    checked={isPushSelected}
                                    onChange={(e) => setIsPushSelected(e.target.checked)}
                                />
                                <div className="toggle-slider"></div>
                            </label>
                            <label className='scheme-name-label'>
                                Push
                            </label>
                        </div>
                    </div>
                    <div className="form-group">
                        <label>Emails for Users:</label>
                        {emails.map((email, index) => (
                            <div key={index} className="email-entry">
                                <input
                                    type="text"
                                    className="form-control email-input"
                                    placeholder="Enter email"
                                    value={email}
                                    onChange={(e) => handleEmailChange(index, e.target.value)}
                                    required
                                />
                                <button
                                    type="button"
                                    className="btn btn-danger delete-email-button"
                                    onClick={() => deleteEmail(index)}
                                >
                                    &#x2715; {/* Use the multiplication sign (×) as a cross */}
                                </button>
                            </div>
                        ))}
                        <div>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={addEmail}
                            >
                                Add Email
                            </button>
                        </div>
                    </div>
                    <div className="form-group">
                        <label>Server Addresses:</label>
                        {ipAddresses.map((ipAddress, index: number) => (
                            <>
                                <div key={index} className="ip-address-entry">
                                    <div className="ip-address-input">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="IP Address (e.g. 10.20.10.20)"
                                            value={ipAddress.ipAddress}
                                            onChange={(e) => handleIpAddressChange(index, e.target.value)}
                                            required />
                                    </div>
                                    <button
                                        type="button"
                                        className="btn btn-danger delete-ip-address-button"
                                        onClick={() => deleteIpAddress(index)}
                                    >
                                        &#x2715;
                                    </button>
                                </div>
                                <div className="exporter-checkboxes">
                                    {['docker', 'system', 'elasticsearch', 'blackbox', 'mongodb', 'mysqld', 'postgresql']
                                        .filter((exporter) => {
                                            if (isPushSelected) {
                                                return ['docker', 'system'].includes(exporter);
                                            }
                                            return true;
                                        })
                                        .map((exporter) => {
                                            const key = `${index}-${exporter}`;
                                            const isExporterSelected = ipAddress.selectedExporters.includes(exporter as never);
                                            const isAuthNeeded = authOptions[key] === 'basic';

                                        return (
                                            <div key={key} className="exporter-option">
                                                <div className="exporter-row">
                                                    <label className='toggle-switch exporter-choosing-checkbox'>
                                                        <input
                                                            type="checkbox"
                                                            name={`exporter-${index}`}
                                                            value={exporter}
                                                            checked={isExporterSelected}
                                                            onChange={() => handleExporterCheckboxChange(index, exporter as never)}
                                                        />
                                                        <div className="toggle-slider"></div>
                                                    </label>
                                                    <label className='exporter-name-label'>
                                                        {exporter}
                                                    </label>
                                                    {isExporterSelected && !isPushSelected && (
                                                        <>
                                                            <label className="auth-toggle-switch auth-option-checkbox">
                                                                <input
                                                                    type="checkbox"
                                                                    checked={isAuthNeeded}
                                                                    onChange={() => handleAuthOptionChange(
                                                                        key,
                                                                        isAuthNeeded ? 'no-auth' : 'basic'
                                                                    )} />
                                                                <div className='auth-toggle-slider'></div>
                                                            </label>
                                                            <label className='auth-option-annotation'>
                                                                Authentication
                                                            </label>
                                                        </>
                                                    )}

                                                </div>

                                                {isAuthNeeded && (
                                                    <div className="auth-input">
                                                        <label>Username:</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            value={usernameOptions[key] || ''}
                                                            onChange={(e) =>
                                                                setUsernameOptions((prevOptions) => ({
                                                                    ...prevOptions,
                                                                    [key]: e.target.value,
                                                                }))
                                                            }
                                                        />
                                                        <label>Password:</label>
                                                        <input
                                                            type="password"
                                                            className="form-control"
                                                            value={passwordOptions[key] || ''}
                                                            onChange={(e) =>
                                                                setPasswordOptions((prevOptions) => ({
                                                                    ...prevOptions,
                                                                    [key]: e.target.value,
                                                                }))
                                                            }
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                            </>
                        ))}
                        {!isPushSelected && (
                            <div>
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={addIpAddress}
                                >
                                    Add IP Address
                                </button>
                            </div>
                        )}
                    </div>

                    <div className="form-group">
                        <div className='notification-option-row'>
                            <label className="notification-toggle-switch notification-option-checkbox">
                                <input
                                    type="checkbox"
                                    checked={notificationsNeeded}
                                    onChange={handleNotificationsToggle}
                                />
                                <div className='notification-toggle-slider'></div>
                            </label>
                            <label className='notification-option-label'>
                                Notifications
                            </label>
                        </div>
                        {notificationsNeeded && (
                            <>
                                <label>Notification Options:</label>
                                <div className='notification-source-row'>
                                    <label className="notification-toggle-switch notification-source-checkbox">
                                        <input
                                            type="checkbox"
                                            id="discord-option"
                                            checked={notificationOptions['discord'] || false}
                                            onChange={() => handleNotificationOptionChange('discord')}
                                             />
                                        <div className='notification-toggle-slider'></div>
                                    </label>
                                    <label className='notification-option-label'>
                                        Discord
                                    </label>
                                </div>
                                {notificationOptions['discord'] && (
                                    <div>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Discord address"
                                            value={notification_discord}
                                            onChange={(e) => setNotificationDiscord(e.target.value)}
                                        />
                                    </div>
                                )}
                                <div className='notification-source-row'>
                                    <label className="notification-toggle-switch notification-source-checkbox">
                                        <input
                                            type="checkbox"
                                            id="email-option"
                                            checked={notificationOptions['email'] || false}
                                            onChange={() => handleNotificationOptionChange('email')}
                                             />
                                        <div className='notification-toggle-slider'></div>
                                    </label>
                                    <label className='notification-option-label'>
                                        Email
                                    </label>
                                </div>
                                {notificationOptions['email'] && (
                                    <div>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Notification email"
                                            value={notification_email}
                                            onChange={(e) => setNotificationEmail(e.target.value)}
                                        />
                                    </div>
                                )}
                                <div className='notification-source-row'>
                                    <label className="notification-toggle-switch notification-source-checkbox">
                                        <input
                                            type="checkbox"
                                            id="telegram-option"
                                            checked={notificationOptions['telegram'] || false}
                                            onChange={() => handleNotificationOptionChange('telegram')}
                                             />
                                        <div className='notification-toggle-slider'></div>
                                    </label>
                                    <label className='notification-option-label'>
                                        Telegram
                                    </label>
                                </div>
                                {notificationOptions['telegram'] && (
                                    <div>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Telegram Chat ID"
                                            value={telegramChatId}
                                            onChange={(e) => setTelegramChatId(e.target.value)}
                                        />
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Telegram Bot Token"
                                            value={telegramBotToken}
                                            onChange={(e) => setTelegramBotToken(e.target.value)}
                                        />
                                    </div>
                                
                                )}
                            </>
                        )}

                    </div>
                    <div className='reject-submit-section'>
                        <button type="submit" className="btn btn-primary submit-newproject-button">
                            Submit
                        </button>
                        <button type="button" className="btn btn-danger clear-newproject-form-button" onClick={resetForm}>
                            Clear
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default NewProject;
