import React from 'react'
import { Link } from 'react-router-dom'
import './GrafanaPage.css'

const GrafanaPage = () => {
    return (
        <div className="row">
            <div className="col-md-6 offset-md-3">
                <div className="grafana-option-section">
                    <h1>Grafana Management</h1>
                    <div className='grafana-option-row'>
                        <Link to="/grafana/dashboard" className="grafana-option-button">
                            Dashboard Management
                        </Link>
                        <Link to="/grafana/folders" className="grafana-option-button">
                            Folder Management
                        </Link>
                        <Link to="/grafana/users" className="grafana-option-button">
                            User Management
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GrafanaPage