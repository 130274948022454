import React, { useEffect, useState } from 'react';
import { User } from '../../types/User';
import httpClient from '../../httpClient';

const HomePage = () => {

  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    (async () => {
      try {
        const resp = await httpClient.get("/@me");
        console.log(resp.data)
        setUser(resp.data);
      } catch (error) { 
        console.log("Not authenticated");
      }
    })();
  }, []); 

  return (
    <div>
      {user != null ? (
        <div>
          <h1>Welcome aboard, Captain { user.email}!</h1>
        </div>
        ) : (
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2 className="text-center">Read Here</h2>
                <div className="about-project">
                  Not authenticated!
                  {/* <div className="iframe-wrapper">
                    <iframe
                      src="https://cabinet.miem.hse.ru/#/project/1510/info"
                      width="100%"
                      height="500px"
                      frameBorder="0"
                    ></iframe>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
      )}
    </div>
  );
};

export default HomePage;
