import React, { useState } from 'react';
import ListUsersComponent from './GrafanaUsersPageComponents/GrafanaListUsersComponent';
import CreateUserComponent from './GrafanaUsersPageComponents/GrafanaCreateUsersComponent';
import UpdateUserComponent from './GrafanaUsersPageComponents/GrafanaUpdateUsersComponent';
import DeleteUserComponent from './GrafanaUsersPageComponents/GrafanaDeleteUsersComponent';
import './GrafanaUsersPage.css';

const GrafanaUsersPage = () => {
    const [isListUsersOpen, setListUsersOpen] = useState(false);
    const [isCreateUserOpen, setCreateUserOpen] = useState(false);
    const [isUpdateUserOpen, setUpdateUserOpen] = useState(false);
    const [isDeleteUserOpen, setDeleteUserOpen] = useState(false);

    const toggleListUsers = () => setListUsersOpen((prev) => !prev);
    const toggleCreateUser = () => setCreateUserOpen((prev) => !prev);
    const toggleUpdateUser = () => setUpdateUserOpen((prev) => !prev);
    const toggleDeleteUser = () => setDeleteUserOpen((prev) => !prev);

    return (
        <div className="row">
            <div className="col-md-6 offset-md-3">
                <div className="grafana-user-section">
                    <h1 className='user-management-title'>User Management Page</h1>
                    <>
                        <div className="grafana-user-action-section">
                            <button
                                className={`user-button square-button ${isListUsersOpen ? 'active' : ''}`}
                                onClick={toggleListUsers}
                            >
                                List Users
                            </button>
                            {isListUsersOpen && <ListUsersComponent />}
                        </div>
                    </>
                    <>
                        <div className="grafana-user-action-section">
                            <button
                                className={`user-button square-button ${isCreateUserOpen ? 'active' : ''}`}
                                onClick={toggleCreateUser}
                            >
                                Create User
                            </button>
                            {isCreateUserOpen && <CreateUserComponent />}
                        </div>
                    </>
                    <>
                        <div className="grafana-user-action-section">
                            <button
                                className={`user-button square-button ${isUpdateUserOpen ? 'active' : ''}`}
                                onClick={toggleUpdateUser}
                            >
                                Update User
                            </button>
                            {isUpdateUserOpen && <UpdateUserComponent />}
                        </div>
                    </>
                    <>
                        <div className="grafana-user-action-section">
                            <button
                                className={`user-button square-button ${isDeleteUserOpen ? 'active' : ''}`}
                                onClick={toggleDeleteUser}
                            >
                                Delete User
                            </button>
                            {isDeleteUserOpen && <DeleteUserComponent />}
                        </div>
                    </>
                </div>
            </div>
        </div>
    );
};

export default GrafanaUsersPage;
