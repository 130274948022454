import React from 'react'
import { Link } from 'react-router-dom'
import './GrafanaDashboardPage.css'

const GrafanaDashboardPage = () => {
    return (
        <div className="row">
            <div className="col-md-6 offset-md-3">
                <div className="grafana-dashboard-option-section">
                    <h1>Grafana Dashboard Management</h1>
                    <div className='grafana-option-row'>
                        <Link to="/grafana/dashboard/create" className="grafana-dashboard-option-button">
                            Create Dashboard
                        </Link>
                        <Link to="/grafana/dashboard/delete" className="grafana-dashboard-option-button">
                            Delete Dashboard
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GrafanaDashboardPage