import React, { useState } from 'react';
import httpClient from '../../../httpClient';
import './GrafanaUpdateUsersComponent.css';

const CreateUserComponent = () => {
  const [formData, setFormData] = useState({
    email: '',
    name: '',
    login: '',
    password: '',
    role: '',
  });

interface Notification {
    type: 'success' | 'error';
    message: string;
  }

const [notification, setNotification] = useState<Notification | null>(null);

  const handleInputChange = (e : any) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleSubmit = async (e : any) => {
    e.preventDefault();
    try {
      const response = await httpClient.post('/grafana/users/create-user', formData);
      console.log(response.data);
      // Display success notification
      setNotification({ type: 'success', message: 'User created successfully' });

    } catch (error) {
      console.error('Error:', error);
      // Display error notification
      setNotification({ type: 'error', message: 'Failed to create user' });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {notification && (
        <div className={`notification ${notification.type}`}>
          {notification.message}
        </div>
      )}
      
      <input type="hidden" name="action" value="create-user" />

      <div className="form-group-grafana-users">
        <label htmlFor="email">Email:</label>
        <input
          type="email"
          name="email"
          id="email"
          autoComplete="off"
          required
          value={formData.email}
          onChange={handleInputChange}
        />
      </div>

      <div className="form-group-grafana-users">
        <label htmlFor="name">Name:</label>
        <input
          type="text"
          name="name"
          id="name"
          autoComplete="off"
          required
          value={formData.name}
          onChange={handleInputChange}
        />
      </div>

      <div className="form-group-grafana-users">
        <label htmlFor="login">Login:</label>
        <input
          type="text"
          name="login"
          id="login"
          autoComplete="off"
          required
          value={formData.login}
          onChange={handleInputChange}
        />
      </div>

      <div className="form-group-grafana-users">
        <label htmlFor="password">Password:</label>
        <input
          type="password"
          name="password"
          id="password"
          autoComplete="off"
          required
          value={formData.password}
          onChange={handleInputChange}
        />
      </div>

      <div className="form-group-grafana-users">
        <label htmlFor="role">Role:</label>
        <input
          type="text"
          name="role"
          id="role"
          autoComplete="off"
          value={formData.role}
          onChange={handleInputChange}
        />
      </div>

      <div className="form-group-grafana-users">
        <input type="submit" value="Submit" />
      </div>
    </form>
  );
};

export default CreateUserComponent;
