import React, { useState, useEffect } from 'react';
import httpClient from '../../../httpClient';
import { GrafanaUser } from '../../../types/GrafanaUser';

const ListUsersComponent = () => {
    const [users, setUsers] = useState([]);
    const [usersLoaded, setUsersLoaded] = useState(false);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await httpClient.get('/grafana/users/list-users');
                setUsers(response.data.users);
                setUsersLoaded(response.data.users_loaded);
            } catch (error) {
                console.error('Error fetching users:', error);
                // Handle error, set state accordingly
            }
        };

        fetchUsers();
    }, []);

    return (
        <div className="user-section" style={{ display: usersLoaded ? 'block' : 'none' }}>
            {usersLoaded ? (
                users.length > 0 ? (
                    <table id="users-table">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th>Login</th>
                                <th>Email</th>
                                <th>Is Admin</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map((user : GrafanaUser) => (
                                <tr key={user.id}>
                                    <td>{user.id}</td>
                                    <td>{user.name}</td>
                                    <td>{user.login}</td>
                                    <td>{user.email}</td>
                                    <td>{user.isAdmin}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p>No users found.</p>
                )
            ) : null}

        </div>
    );
};

export default ListUsersComponent;