import React, { useState } from 'react';
import { NavLink } from 'react-router-dom'; // Import NavLink from react-router-dom
import './Sidebar.css'; // Import your Sidebar component styles

interface SidebarProps {
  isAuthenticated: boolean;
}

const Sidebar: React.FC<SidebarProps> = ({ isAuthenticated }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const handleSidebarToggle = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <div className={`sidebar ${sidebarOpen ? 'show' : ''}`}>
      <div className={`arrow-container ${sidebarOpen ? 'open' : ''}`} onClick={handleSidebarToggle} style={{ left: sidebarOpen ? '320px' : '20px' }}>
        <div className="arrow-btn">
          <span className={`arrow-icon ${sidebarOpen ? 'arrow-left' : ''}`}>→</span>
        </div>
      </div>
      <ul className="sidebar-menu">
        { isAuthenticated && (
        <React.Fragment>
          <li className="sidebar-menu-item">
            <NavLink className="sidebar-menu-link" to="/installserver">Server Setup</NavLink>
          </li>
          <li className="sidebar-menu-item">
            <NavLink className="sidebar-menu-link" to="/newproject">New project</NavLink>
          </li>
          <li className="sidebar-menu-item">
            <NavLink className="sidebar-menu-link" to="/deleteproject">Delete Project</NavLink>
          </li>
          <li className="sidebar-menu-item">
            <NavLink className="sidebar-menu-link" to="/ansible">Ansible</NavLink>
          </li>
          <li className="sidebar-menu-item">
            <NavLink className="sidebar-menu-link" to="/grafana">Grafana</NavLink>
          </li>
          <li className="sidebar-menu-item">
            <NavLink className="sidebar-menu-link" to="/alerting">Alerting</NavLink>
          </li>
        </React.Fragment>)}
        <li className="sidebar-menu-item">
          <NavLink className="sidebar-menu-link" to="/documentation">Documentation</NavLink>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
