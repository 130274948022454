import React, { useState } from 'react';
import Modal from 'react-modal'; // Import React Modal
import httpClient from '../../httpClient';
import './DeleteProject.css'

const DeleteProject = () => {
    const [projName, setProjName] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleSubmit = async () => {

        // If you want to open the modal before deleting, you can call openModal here.
        openModal();

        const deleteProjectData = {
            projName: projName,
        };

        try {
            // Send the data as JSON to the '/deleteproject' API when OK is clicked in the modal
            // You should handle the API request here.
            console.log('Deleting project:', deleteProjectData);
            const response = await httpClient.post('/deleteproject', deleteProjectData);
            console.log(response.data);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <div className="row">
            <div className="col-md-6 offset-md-3">
                <form onSubmit={handleSubmit} className="delete-project-form">
                    <div className="form-group">
                        <label htmlFor="proj-name">Project to delete</label>
                        <input
                            type="text"
                            className="form-control"
                            id="proj-name"
                            value={projName}
                            onChange={(e) => setProjName(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <button type="button" className="btn btn-danger" onClick={openModal}>
                            Delete Project
                        </button>
                    </div>
                </form>
            </div>
            <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                // contentLabel="Delete Confirmation Modal"
                // portalClassName='modal'
            >
                <h2 className='make-sure-text'>
                    Are you sure you want to delete the project?
                </h2>
                <p className='make-sure-text-2'>
                    This action will delete all of its data.
                </p>
                <div>
                    <button className="btn btn-danger" onClick={closeModal}>
                        Cancel
                    </button>
                    <button className="btn btn-primary" onClick={() => { handleSubmit(); closeModal(); }}>
                        OK
                    </button>
                </div>
            </Modal>
        </div>
    );
};

export default DeleteProject;
