import React, { useState } from 'react';
import httpClient from '../../httpClient'; // Make sure to import your HTTP client or fetch function
import './LoginPage.css'

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = async (e: any) => {
    e.preventDefault();

    try {
      const response = await httpClient.post('/login', { email, password });

      // Handle successful login response here (e.g., redirect to dashboard)
      console.log(response.data)
      if (response.status === 200) {
        window.location.href = '/';
      }
    } catch (error: any) {
      // Handle login error here (e.g., show error message)
      console.error('Login error:', error);
      if (error.response.status === 401) {
        alert('Invalid credentials!')
      }
    }
  };

  return (
    <div className="row">
      <div className="col-md-6 offset-md-3">
        <form className="login-form" onSubmit={handleLogin}>
          <h2>Login</h2>
          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              className="form-control"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>Password</label>
            <input
              type="password"
              className="form-control"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="btn btn-primary">
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;