import React from 'react';
import './Documentation.css'; // Импортируем файл стилей

const Documentation = () => {
  return (
    <div className="documentation-container">
      <h1>Documentation Links</h1>
      <ul>
        <li>
          <strong>Документация пользователя (Grafana)</strong>{' '}
          <a href="https://git.miem.hse.ru/1510/public_data/-/wikis/%D0%94%D0%BE%D0%BA%D1%83%D0%BC%D0%B5%D0%BD%D1%82%D0%B0%D1%86%D0%B8%D1%8F-%D0%BF%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D1%8F(Grafana)" target="_blank" rel="noopener noreferrer">
            User_Grafana
          </a>
        </li>
        <li>
          <strong>Документация пользователя (Web interface)</strong>{' '}
          <a href="https://git.miem.hse.ru/1510/public_data/-/wikis/%D0%94%D0%BE%D0%BA%D1%83%D0%BC%D0%B5%D0%BD%D1%82%D0%B0%D1%86%D0%B8%D1%8F-%D0%BF%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D1%8F(Web-interface)" target="_blank" rel="noopener noreferrer">
            User_Web-interface
          </a>
        </li>
        <li>
          <strong>Документация пользователя (Экспортеры):</strong>{' '}
          <a href="https://git.miem.hse.ru/1510/public_data/-/wikis/%D0%94%D0%BE%D0%BA%D1%83%D0%BC%D0%B5%D0%BD%D1%82%D0%B0%D1%86%D0%B8%D1%8F-%D0%BF%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D1%8F-%D0%BF%D0%BE-%D1%8D%D0%BA%D1%81%D0%BF%D0%BE%D1%80%D1%82%D0%B5%D1%80%D0%B0%D0%BC" target="_blank" rel="noopener noreferrer">
            User_exporters
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Documentation;