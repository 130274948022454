import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Sidebar from './components/Sidebar/Sidebar';
import NavigationBar from './components/NavigationBar/NavigationBar';
import HomePage from './pages/HomePage/HomePage';
import NotFound from './pages/NotFound/NotFound';
import LoginPage from './pages/LoginPage/LoginPage';
import httpClient from './httpClient';
import { User } from './types/User';
import { PrivateRouteProps } from './types/PrivateRoute';
import NewProject from './pages/NewProject/NewProject';
import ProfilePage from './pages/ProfilePage/ProfilePage';
import RegisterPage from './pages/RegisterPage/RegisterPage';
import ServerSetup from './pages/ServerSetup/ServerSetup';
import DeleteProject from './pages/DeleteProjectPage/DeleteProjectPage';
import GrafanaPage from './pages/GrafanaPage/GrafanaPage';
import GrafanaDashboardPage from './pages/GrafanaDashboardPage/GrafanaDashboardPage';
import GrafanaDashboardPageCreate from './pages/GrafanaDashboardPage/GrafanaDashboardPageCreate';
import GrafanaUsersPage from './pages/GrafanaUsersPage/GrafanaUsersPage';
import Documentation from './pages/Documentation/Documentation';

// Custom HOC for authenticated routes
function PrivateRoute({ element, isAuthenticated, isSecureAuthSet, ...rest }: PrivateRouteProps) {
  console.log(isAuthenticated, isSecureAuthSet);
  if (!isSecureAuthSet) {
    return element;
  } else if (isAuthenticated) {
    return element;
  } else {
    return <Navigate to="/login" />;
  }
}

function App() {

  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    (async () => {
      try {
        const resp = await httpClient.get("/@me");
        setUser(resp.data);
      } catch (error) {
        setUser(null);
      }
    })();
  }, []);

  return (
    <Router>
      <div className="app-container">
        <Sidebar isAuthenticated={Boolean(user)}/>
        <div className="content-container">
          <NavigationBar isAuthenticated={Boolean(user)} isSecureAuthSet={Boolean(!(user?.email === "anonymous" && user.id === "0000-0000-0000-0000"))}/>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route
              path="/newproject"
              element={<PrivateRoute element={<NewProject />} isAuthenticated={Boolean(user)} isSecureAuthSet={Boolean(!(user?.email === "anonymous" && user.id === "0000-0000-0000-0000"))}/>}
            />
            <Route
              path="/installserver"
              element={<PrivateRoute element={<ServerSetup />} isAuthenticated={Boolean(user)} isSecureAuthSet={Boolean(!(user?.email === "anonymous" && user.id === "0000-0000-0000-0000"))}/>}
            />
            <Route
              path="/deleteproject"
              element={<PrivateRoute element={<DeleteProject />} isAuthenticated={Boolean(user)} isSecureAuthSet={Boolean(!(user?.email === "anonymous" && user.id === "0000-0000-0000-0000"))}/>}
            />
            <Route
              path="/profile"
              element={<PrivateRoute element={<ProfilePage user={user} />} isAuthenticated={Boolean(user)} isSecureAuthSet={Boolean(!(user?.email === "anonymous" && user.id === "0000-0000-0000-0000"))}/>}
            />
            <Route
              path="/grafana"
              element={<PrivateRoute element={<GrafanaPage />} isAuthenticated={Boolean(user)} isSecureAuthSet={Boolean(!(user?.email === "anonymous" && user.id === "0000-0000-0000-0000"))}/>}
            />
            <Route
              path="/grafana/dashboard"
              element={<PrivateRoute element={<GrafanaDashboardPage />} isAuthenticated={Boolean(user)} isSecureAuthSet={Boolean(!(user?.email === "anonymous" && user.id === "0000-0000-0000-0000"))}/>}
            />
            <Route
              path="/grafana/dashboard/create"
              element={<PrivateRoute element={<GrafanaDashboardPageCreate />} isAuthenticated={Boolean(user)} isSecureAuthSet={Boolean(!(user?.email === "anonymous" && user.id === "0000-0000-0000-0000"))}/>}
            />
            <Route
              path="/grafana/users"
              element={<PrivateRoute element={<GrafanaUsersPage />} isAuthenticated={Boolean(user)} isSecureAuthSet={Boolean(!(user?.email === "anonymous" && user.id === "0000-0000-0000-0000"))}/>}
            />
            <Route
              path="/documentation"
              element={<PrivateRoute element={<Documentation />} isAuthenticated={Boolean(user)} isSecureAuthSet={Boolean(!(user?.email === "anonymous" && user.id === "0000-0000-0000-0000"))}/>}
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
