import React, { useState } from 'react'
import './GrafanaDashboardPageCreate.css'
import uploadImg from './upload_1.png';

const GrafanaDashboardPageCreate = () => {
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [rawJson, setRawJson] = useState<string>('');
  interface Notification {
    type: 'success' | 'error';
    message: string;
  }

  const [notification, setNotification] = useState<Notification | null>(null);

  const handleFileUpload = (file: File | null) => {
    if (file) {
      // Handle file upload logic here, e.g., save the file to state
      setUploadedFile(file);
    }
  };

  const handleFileDelete = () => {
    setUploadedFile(null);
  };

  const handleJsonChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const jsonValue = event.target.value;
    // Handle JSON input logic here, e.g., save JSON to state
    setRawJson(jsonValue);
  };

  const handleSubmit = async () => {
    try {
      if (uploadedFile && rawJson) {
        // Handle both file and raw JSON
        
        const formData = new FormData();
        formData.append('file', uploadedFile);
        formData.append('json', rawJson);
  
        const response = await fetch('/upload-grafana-dash', {
          method: 'POST',
          body: formData,
          headers: {
            'Accept': 'application/json',
          },
        });
  
        if (response.ok) {
          setNotification({ type: 'success', message: 'Dashboard upload successfully' });
          console.log('Data sent successfully');
        } else {
          setNotification({ type: 'error', message: 'Failed to upload dashboard' });
          console.error('Error:', response.status, response.statusText);
        }
      } else if (uploadedFile) {
        // Handle only file
        const formData = new FormData();
        formData.append('file', uploadedFile);
  
        const response = await fetch('/upload-grafana-dash', {
          method: 'POST',
          body: formData,
          headers: {
            'Accept': 'application/json',
          },
        });
  
        if (response.ok) {
          setNotification({ type: 'success', message: 'Dashboard upload successfully' });
          console.log('File sent successfully');
        } else {
          setNotification({ type: 'error', message: 'Failed to upload dashboard' });
          console.error('Error:', response.status, response.statusText);
        }
      } else if (rawJson) {
        // Handle only raw JSON

        const formData = new FormData();
        formData.append('json', rawJson);
        const response = await fetch('/upload-grafana-dash', {
          method: 'POST',
          body: formData,
          headers: {
            'Accept': 'application/json',
          },
        });
  
        if (response.ok) {
          setNotification({ type: 'success', message: 'Dashboard upload successfully' });
          console.log('JSON sent successfully');
        } else {
          setNotification({ type: 'error', message: 'Failed to upload dashboard' });
          console.error('Error:', response.status, response.statusText);
        }
      } else {
        console.error('No data to send');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className='row'>
      <div className="col-md-6 offset-md-3">
        <div className="grafana-dashboard-creation-section">
          <h2>Create Dashboard</h2>
          <div className='grafana-dashboard-upload-row'>
            <h3>Upload File</h3>
            <div className='upload-file-input'>
              <img src={uploadImg} alt="upload" />
              <input
                type="file"
                onChange={(e) => handleFileUpload(e.target.files?.[0] || null)}
              />

            </div>
            <p>{uploadedFile ? uploadedFile.name : "File not chosen"}</p>
            {uploadedFile && (
              <button className='delete-file-button' onClick={handleFileDelete}>
                Delete File
              </button>
            )}
          </div>
          <div className='grafana-dashboard-raw-row'>
            <h3>Insert Raw JSON</h3>
            <textarea className='textarea-json'
              value={rawJson}
              onChange={handleJsonChange}
              rows={5}
            ></textarea>
          </div>
          <div className='notification-container'>
          {notification && (
            <div className={`notification ${notification.type}`}>
              {notification.message}
            </div>
          )}
          </div>
          <button className='grafana-dashboard-creation-button' onClick={handleSubmit}>Submit</button>
        </div>
      </div>
    </div>
  );
};

export default GrafanaDashboardPageCreate