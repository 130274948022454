import React, { useState } from 'react';
import { User } from '../../types/User';
import httpClient from '../../httpClient'; // Make sure to import your HTTP client instance here
import './ProfilePage.css';

interface ProfilePageProps {
  user: User | null;
}

const ProfilePage: React.FC<ProfilePageProps> = ({ user }) => {
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [passwordChangeMessage, setPasswordChangeMessage] = useState('');

  const handleChangePasswordClick = () => {
    setShowChangePassword(!showChangePassword);
    setCurrentPassword('');
    setNewPassword('');
    setPasswordChangeMessage('');
  };

  const handleConfirmPasswordChange = async () => {
    try {
      const response = await httpClient.post('/change-password', {
        current_password: currentPassword,
        new_password: newPassword,
        user_id: user?.id,
      });
      setPasswordChangeMessage(response.data.message);
    } catch (error: any) {
      setPasswordChangeMessage('Error: ' + error.message);
    }
  };

  return (
    <div className="profile-container">
      <div className="profile-header">
        <h2>Your Profile</h2>
      </div>
      {user ? (
        <div className="profile-info">
          <div className="profile-info-row">
            <div className="profile-info-label">Email:</div>
            <div className="profile-info-value">{user.email}</div>
          </div>
          <div className="profile-info-row">
            <div className="profile-info-label">User ID:</div>
            <div className="profile-info-value">{user.id}</div>
          </div>
          <button className="change-password-button" onClick={handleChangePasswordClick}>
            Change Password
          </button>
          {showChangePassword && (
            <div className="change-password-fields">
              <input
                type="password"
                placeholder="Current Password"
                className="password-field"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
              />
              <input
                type="password"
                placeholder="New Password"
                className="password-field"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <button className="confirm-password-change-button" onClick={handleConfirmPasswordChange}>
                Confirm
              </button>
              <div className="password-change-message">{passwordChangeMessage}</div>
            </div>
          )}
        </div>
      ) : (
        <div className="profile-not-logged-in">You are not logged in.</div>
      )}
    </div>
  );
};

export default ProfilePage;
