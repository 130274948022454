import React, { useState } from 'react';
import httpClient from '../../../httpClient';
import './GrafanaUpdateUsersComponent.css';

const DeleteUserComponent = () => {
  const [email, setEmail] = useState('');
  const [isDeleted, setIsDeleted] = useState(false);

  interface Notification {
    type: 'success' | 'error';
    message: string;
  }

  const [notification, setNotification] = useState<Notification | null>(null);

  const handleSubmit = async (e : any) => {
    e.preventDefault();

    try {
      // Implement the API call to delete the user with the provided email
      const response = await httpClient.post('/grafana/users/delete-user', { 'email': email });

      // Handle success, update state, show a message, or redirect as needed
      setIsDeleted(true);
      // Display success notification
      setNotification({ type: 'success', message: 'User deleted successfully' });
      } catch (error) {
      // Display error notification
      setNotification({ type: 'error', message: 'Failed to deleted user' });
      console.error('Error deleting user:', error);
      // Handle error, show an error message, or log the error
    }
  };

  return (
    <div className="user-section">
      <form onSubmit={handleSubmit}>
      {notification && (
        <div className={`notification ${notification.type}`}>
          {notification.message}
        </div>
      )}
        <div className="form-group-grafana-users">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            name="email"
            id="email"
            autoComplete="off"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        <div className="form-group-grafana-users">
          <input type="submit" value="Submit" />
        </div>
      </form>
    </div>
  );
};

export default DeleteUserComponent;
